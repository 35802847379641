@import url('assets/vendor/css/core.css');
@import url('assets/vendor/css/theme-default.css');
@import url('assets/vendor/css/boxicons/boxicons.css');
@import url('assets/vendor/css/fontawesome/fontawesome.css');
/* refer the styles from package folder */



.p-inputtext {
    padding: 8px !important;
}

.p-datatable .p-datatable-thead>tr:nth-child(2)>th {
    padding: 10px !important;
}

.p-hidden-space {
    display: none;
}

.p-datatable .p-column-header-content {
    display: block !important;
}

.invalid-feedback {
    margin-bottom: 0 !important;
}
