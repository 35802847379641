 /* .active { */
    /* color: #8587FF !important; */
    /* width: 100% ; */
    /* background-color: #E7E7FF; */
/* } */
/* 
.layout-wrapper:not(.layout-horizontal) .bg-menu-theme .menu-inner > .menu-item.active:before {
    content: "";
    position: absolute;
    right: 0;
    width: 0.25rem;
    height: 2.6845rem;
    border-radius: 0.375rem 0 0 0.375rem;
} */

/*
.newMenu {
    display: flex;
    align-items: center;
    margin-left: 1.5rem !important;
}

.max-max {
    max-height: 6vh !important;
}

.max-max:hover {
    color: #8587ff !important;
    background-color: #e7e7ff66;
} */

.layout-wrapper:not(.layout-horizontal) .bg-menu-theme .menu-inner>.menu-item.active:before {
    height: 2.5rem !important;
}

